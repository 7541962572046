import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type {
  IVideoPlayerControllerProps,
  IVideoPlayerMapperProps,
  IVideoPlayerStateRefValues,
  IVideoPlayerProps,
} from '../VideoPlayer.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

import { isExperimentOpen } from '@wix/editor-elements-common-utils';

export default withCompController<
  IVideoPlayerMapperProps,
  IVideoPlayerControllerProps,
  IVideoPlayerProps,
  IVideoPlayerStateRefValues
>(({ stateValues, mapperProps }) => {
  const { reducedMotion, experiments = {} } = stateValues;

  const isClassNameToRootEnabled = isExperimentOpen(
    experiments,
    'specs.thunderbolt.isClassNameToRootEnabled',
  );

  return {
    ...mapperProps,
    isClassNameToRootEnabled,
    reducedMotion,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});
